import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  AppstoreOutlined,
  PicLeftOutlined,
  SettingOutlined,
  FileProtectOutlined,
  FileImageOutlined,
  UserOutlined,
} from '@ant-design/icons';
import config from '../../config';
import { useUserContext } from '../../context/user.context';
import { UserRole } from '../../consts/UserRoleEnum';
import styles from './navigation.module.css';
import useResponsiveLayout from '../../hooks/useResponsiveLayout';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const Navigation = ({
  setCollapsed,
}: {
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { t } = useTranslation('common');
  const [selectedKeys, setSelectedKeys] = useState<string[]>();
  const [openKeys, setOpenKeys] = useState<string[]>();
  useEffect(() => {
    setSelectedKeys(['/' + location.pathname.split('/')[1], location.pathname]);
    setOpenKeys(['/' + location.pathname.split('/')[1], location.pathname]);
  }, [location, location.pathname]);
  const { isMobileLayout } = useResponsiveLayout();

  const getMenuItems = useMemo(() => {
    const adminMenu: MenuProps['items'] = [
      getItem(t('sidebar.navigation.companies'), 'Companies', <AppstoreOutlined />, [
        getItem(t('sidebar.navigation.companies'), config.routes.companies, null),
        getItem(t('sidebar.navigation.addCompany'), config.routes.addCompany, null),
      ]),
      getItem(t('sidebar.navigation.invoices'), config.routes.invoices, <FileProtectOutlined />),
      getItem(t('sidebar.navigation.articles'), config.routes.articles, <FileImageOutlined />, [
        getItem(t('sidebar.navigation.allArticles'), config.routes.allArticles, null),
        getItem(t('sidebar.navigation.addArticle'), config.routes.addArticle, null),
      ]),
      getItem(t('sidebar.navigation.events'), 'Events', <FileImageOutlined />, [
        getItem(t('sidebar.navigation.allEvents'), config.routes.events, null),
        getItem(t('sidebar.navigation.addEvent'), config.routes.addEvent, null),
      ]),
    ];

    const memberMenu: MenuProps['items'] = [
      getItem(t('sidebar.navigation.companies'), config.routes.companies, <AppstoreOutlined />),
      getItem(
        t('sidebar.navigation.myCompany'),
        config.routes.company.replace(':id', String(user.id)),
        <PicLeftOutlined />,
      ),
      getItem(t('sidebar.navigation.invoices'), config.routes.invoices, <FileProtectOutlined />, [
        getItem(t('sidebar.navigation.allInvoices'), config.routes.allInvoices, null),
        getItem(t('sidebar.navigation.addInvoice'), config.routes.addInvoice, null),
      ]),
      getItem(t('sidebar.navigation.articles'), config.routes.articles, <FileImageOutlined />, [
        getItem(t('sidebar.navigation.allArticles'), config.routes.allArticles, null),
        getItem(t('sidebar.navigation.addArticle'), config.routes.addArticle, null),
      ]),
      getItem(t('sidebar.navigation.settings'), config.routes.editMyCompany, <SettingOutlined />),
    ];

    if (user.role === UserRole.SUPER_ADMIN) {
      adminMenu.push(
        getItem(t('sidebar.navigation.admins'), 'Admins', <UserOutlined />, [
          getItem(t('sidebar.navigation.allAdmins'), config.routes.admins, null),
          getItem(t('sidebar.navigation.addAdmin'), config.routes.addAdmin, null),
        ]),
      );
    }

    if (user.role === UserRole.SUPER_ADMIN || user.role === UserRole.ADMIN) {
      return adminMenu;
    }

    return memberMenu;
  }, [user, t]);

  const onMenuClick = useCallback(
    (key: string) => {
      if (isMobileLayout) setCollapsed(true);
      navigate(key);
    },
    [isMobileLayout, navigate, setCollapsed],
  );

  return (
    <>
      <Menu
        openKeys={openKeys}
        selectedKeys={selectedKeys}
        mode='inline'
        items={getMenuItems}
        onClick={({ key }) => {
          onMenuClick(key);
        }}
        onOpenChange={(openKeys) => {
          setOpenKeys(openKeys);
        }}
        className={styles.root}
      />
    </>
  );
};

export default Navigation;
