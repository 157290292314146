import React, { ReactElement } from 'react';
import { Form, Input, Select, DatePicker, Badge } from 'antd';
import { useTranslation } from 'react-i18next';
import { LockOutlined } from '@ant-design/icons';
import { UserStatus } from '../../../consts/UserStatus';
import { UserCategory } from '../../../consts/UserCategoryEnum';
import config from '../../../config';
import { CompanyFormInterface } from '../../../models/companyFormInterface';
import useCompanyForm from '../../../hooks/useCompanyForm';
import PaymentProtectionSection from './paymentProtection';
import SubcategorySelect from './subcategorySelect';

const FormAccount = ({
  formValues,
  isEditCompany,
  selfEditMember,
}: {
  formValues: CompanyFormInterface;
  isEditCompany?: boolean;
  selfEditMember?: boolean;
}): ReactElement => {
  const { t } = useTranslation('common');
  const { phoneNumberValidator } = useCompanyForm({});

  return (
    <div>
      {!selfEditMember && (
        <>
          <Form.Item
            label={t('misc.status')}
            name='status'
            rules={[
              {
                required: true,
                message: t('messages.requiredField') ?? '',
              },
            ]}
          >
            <Select>
              <Select.Option value={UserStatus.ENABLED}>
                <Badge color={config.userStatusColor.enabled} text={t('status.active')} />
              </Select.Option>
              <Select.Option value={UserStatus.HIDDEN}>
                <Badge color={config.userStatusColor.hidden} text={t('status.hidden')} />
              </Select.Option>
              <Select.Option value={UserStatus.ARCHIVED}>
                <Badge color={config.userStatusColor.archived} text={t('status.archived')} />
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={t('misc.registrationDate')}
            name='registrationDate'
            rules={[
              {
                required: true,
                message: t('messages.requiredField') ?? '',
              },
            ]}
          >
            <DatePicker format={config.dateFormat.universalFormat} />
          </Form.Item>
          <Form.Item
            label={t('misc.membershipDue')}
            name='membershipDue'
            rules={[
              {
                required: true,
                message: t('messages.requiredField') ?? '',
              },
            ]}
          >
            <DatePicker format={config.dateFormat.universalFormat} />
          </Form.Item>
          <Form.Item
            label={t('misc.vatNo')}
            name='vatNo'
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('misc.category')}
            name='userCategory'
            rules={[
              {
                required: true,
                message: t('messages.requiredField') ?? '',
              },
            ]}
          >
            <Select
              placeholder={t('messages.selectCompanyPlaceholder')}
              options={[
                {
                  label: t('companyCategories.member'),
                  value: UserCategory.MEMBER,
                },
                {
                  label: t('companyCategories.partner'),
                  value: UserCategory.PARTNER,
                },
              ]}
            />
          </Form.Item>
          {config.features.nsrnCategories && <SubcategorySelect formValues={formValues} />}
          {formValues.userCategory === UserCategory.PARTNER && 
            <Form.Item
              label={t('misc.partnerSpecifics')}
              name='partnerOtherCategory'
              tooltip={t('tooltip.partnerSpecifics').replace(
                ':specificsLength',
                `${config.limits.maxSpecificsLength}`,
              )}
              rules={[
                {
                  max: config.limits.maxSpecificsLength,
                  message: t('messages.specificsLength').replace(
                    ':nmbChars',
                    `${config.limits.maxSpecificsLength}`)
                },
              ]}
            >
              <Input maxLength={config.limits.maxSpecificsLength} />
            </Form.Item>
          }
          {config.features.paymentProtection && (
            <PaymentProtectionSection formValues={formValues} />
          )}
        </>
      )}
      <Form.Item
        label={t('misc.username')}
        name='username'
        rules={[
          {
            required: true,
            message: t('messages.requiredField') ?? '',
          },
        ]}
      >
        <Input disabled={selfEditMember} />
      </Form.Item>
      <Form.Item
        label={t('misc.primaryContact')}
        name='primaryContact'
        rules={[
          {
            required: true,
            message: t('messages.requiredField') ?? '',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t('misc.email')}
        name='email'
        rules={[
          {
            required: true,
            type: 'email',
            message: t('messages.emailValidation') ?? '',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t('misc.phoneNo')}
        name='phoneNo'
        rules={[
          {
            validator: phoneNumberValidator,
          },
        ]}
      >
        <Input />
      </Form.Item>

      {!selfEditMember ? (
        <Form.Item
          label={!(isEditCompany ?? false) ? t('misc.password') : t('misc.newPassword')}
          name='password'
          rules={[
            {
              required: !(isEditCompany ?? false),
              message: t('messages.requiredField') ?? '',
            },
            {
              min: config.minPasswordLength,
              message: t('messages.passwordMinLentgh').replace(
                ':length',
                String(config.minPasswordLength),
              ),
            },
          ]}
        >
          <Input
            placeholder={isEditCompany ?? false ? String(t('messages.newPasswordPlaceholder')) : ''}
          />
        </Form.Item>
      ) : (
        <>
          <Form.Item
            label={t('misc.currentPassword')}
            name='oldPassword'
            rules={[
              {
                required: !(formValues.password === '' || formValues.password === undefined),
                message: t('messages.requiredField') ?? '',
              },
            ]}
          >
            <Input prefix={<LockOutlined className='site-form-item-icon' />} type='password' />
          </Form.Item>
          <Form.Item
            name='password'
            label={t('misc.newPassword')}
            rules={[
              {
                min: config.minPasswordLength,
                message: t('messages.passwordMinLentgh').replace(
                  ':length',
                  String(config.minPasswordLength),
                ),
              },
            ]}
          >
            <Input type='password' prefix={<LockOutlined className='site-form-item-icon' />} />
          </Form.Item>
          <Form.Item
            name='confirmPassword'
            dependencies={['password']}
            label={t('misc.repeatPassword')}
            rules={[
              {
                required: !(formValues.password === '' || formValues.password === undefined),
                message: t('messages.requiredField') ?? '',
              },
              ({ getFieldValue }) => ({
                async validator(_, value) {
                  const newPassword = getFieldValue('password');
                  if (newPassword === undefined || !value) return;
                  if (newPassword === value) return;
                  return await Promise.reject(new Error(t('login.required.matchPasswords') ?? ''));
                },
              }),
            ]}
          >
            <Input prefix={<LockOutlined className='site-form-item-icon' />} type='password' />
          </Form.Item>
        </>
      )}
    </div>
  );
};

export default FormAccount;
