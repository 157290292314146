import React, { ReactElement, SetStateAction } from 'react';
import { Form, Input, Select, DatePicker, Upload, Button, Col, Row, FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadOutlined, DeleteOutlined, ProfileOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styles from './formStyles.module.css';
import useUploadField from '../../../hooks/useUploadField';
import config from '../../../config';
import { CompanyFormInterface } from '../../../models/companyFormInterface';
import LogoBoxDisplay from '../../logoBoxDisplay/logoBoxDisplay';
import { LocalStorageEnum } from '../../../consts/LocalStorageEnum';
import useAddress from '../../../hooks/useAddress';

const FormAbout = ({
  formValues,
  form,
  logoPreviewUrl,
  setLogoPreviewUrl,
  setFormValues,
  selfEditMember
}: {
  formValues: CompanyFormInterface;
  form: FormInstance;
  logoPreviewUrl: string | null;
  setLogoPreviewUrl: React.Dispatch<SetStateAction<string | null>>;
  setFormValues: React.Dispatch<SetStateAction<CompanyFormInterface>>;
  selfEditMember: boolean;
}): ReactElement => {
  const localStorageFileToken = localStorage.getItem(LocalStorageEnum.FILE_ACCESS_TOKEN);
  const sessionStorageFileToken = sessionStorage.getItem(LocalStorageEnum.FILE_ACCESS_TOKEN);
  const { t } = useTranslation('common');
  const { beforeUploadDoc, beforeUploadImage } = useUploadField();

  const { countries, counties, cities, setCountry, setCounty, loading } = useAddress({ initCountry: formValues.country, initCounty: formValues.county });

  const onChangeCounty = (value: string): void => {
    form.setFieldValue('city', undefined);
    setCounty(value);
  };

  const onChangeCountry = (value: string): void => {
    form.setFieldValue('county', undefined);
    form.setFieldValue('city', undefined);
    setCountry(value);
  };

  const removeLogo = (): void => {
    setLogoPreviewUrl(null);
    form.setFieldValue('companyLogo', undefined);
    setFormValues({
      ...formValues,
      companyLogo: undefined,
    });
  };

  const removePresentation = (): void => {
    form.setFieldValue('companyPresentation', undefined);
    setFormValues({
      ...formValues,
      companyPresentation: undefined,
    });
  };

  return (
    <div>
      <Row gutter={50}>
        <Col xs={24} md={17}>
          <Form.Item
            label={t('misc.companyName')}
            name='companyName'
            rules={[
              {
                required: true,
                message: t('messages.requiredField') ?? '',
              },
            ]}
          >
            <Input />
          </Form.Item>
          {config.features.establishedInField && (
            <Form.Item label={t('misc.establishedIn')} name='establishedIn'>
              <DatePicker picker='year' />
            </Form.Item>
          )}
          <Form.Item
            label={t('misc.website')}
            name='website'
            rules={[
              {
                type: 'url',
                message: t('messages.invalidUrl') ?? '',
              },
            ]}
          >
            <Input addonBefore='https://' />
          </Form.Item>
          <h3>{t('misc.address')}</h3>
          <Form.Item
            label={t('misc.country')}
            name='country'
            rules={[
              {
                required: true,
                message: t('messages.requiredField') ?? '',
              },
            ]}
          >
            <Select
              allowClear
              onChange={onChangeCountry}
              showSearch
              disabled={selfEditMember}
              placeholder={t('messages.searchToSelect')}
              options={countries}
              loading={loading}
            />
          </Form.Item>
          <Form.Item
            label={t('misc.county')}
            name='county'
            rules={[
              {
                required: true,
                message: t('messages.requiredField') ?? '',
              },
            ]}
          >
            <Select
              allowClear
              showSearch
              onChange={onChangeCounty}
              disabled={formValues.country === undefined}
              placeholder={
                formValues.country !== undefined
                  ? t('messages.searchToSelect')
                  : t('messages.selectCountryFirst')
              }
              options={counties}
              loading={loading}
            />
          </Form.Item>
          <Form.Item
            label={t('misc.city')}
            name='city'
            rules={[
              {
                required: true,
                message: t('messages.requiredField') ?? '',
              },
            ]}
          >
            <Select
              allowClear
              showSearch
              disabled={formValues.county === undefined}
              placeholder={
                formValues.county !== undefined
                  ? t('messages.searchToSelect')
                  : t('messages.selectCountyFirst')
              }
              options={cities}
              loading={loading}
            />
          </Form.Item>
          <Form.Item
            label={t('misc.addressStreet')}
            name='address'
            rules={[
              {
                required: true,
                message: t('messages.requiredField') ?? '',
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label={t('misc.addressDetails')} name='address2'>
            <Input />
          </Form.Item>
          <Form.Item label={t('misc.postalCode')} name='postalCode'>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={7}>
          <Form.Item
            label={t('misc.logo')}
            tooltip={t('tooltip.onlyImages').replace(
              ':fileSize',
              String(config.limits.maxImageSize),
            )}
          />
          {logoPreviewUrl != null && (
            <div className={styles.logoWrapper}>
              <LogoBoxDisplay>
                <img src={logoPreviewUrl} className={styles.companyLogo} alt='Company Logo' />
              </LogoBoxDisplay>
              <Button
                icon={<DeleteOutlined />}
                onClick={removeLogo}
                className={styles.removeLogo}
              />
            </div>
          )}
          <Form.Item name='companyLogo' valuePropName='companyLogo'>
            <Upload
              maxCount={1}
              multiple={false}
              accept={config.acceptedFileFormats.imagesFormats}
              beforeUpload={beforeUploadImage}
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />} type='primary'>
                {logoPreviewUrl !== null ? t('buttons.uploadNew') : t('buttons.upload')}
              </Button>
            </Upload>
          </Form.Item>
        </Col>
      </Row>
      <h3>{t('misc.presentation')}</h3>
      {!config.features.nsrnCategories && (
        <Form.Item label={t('sidebar.navigation.services')} name='servicesOffered'>
          <Input />
        </Form.Item>
      )}
      <Form.Item
        label={t('misc.companyPresentation')}
        name='companyPresentation'
        valuePropName='companyPresentation'
        tooltip={t('tooltip.companyPresentation').replace(
          ':fileSize',
          String(config.limits.maxDocFileSize),
        )}
      >
        {formValues.companyPresentation?.fileId === undefined ? (
          <Upload
            maxCount={1}
            multiple={false}
            accept={config.acceptedFileFormats.docsFormats}
            beforeUpload={beforeUploadDoc}
            showUploadList={true}
          >
            {(!formValues.companyPresentation ||
              formValues.companyPresentation?.fileList?.length === 0) && (
              <Button icon={<UploadOutlined />}>{t('buttons.clickToUpload')}</Button>
            )}
          </Upload>
        ) : (
          <>
            <Link
              to={`${config.fileViewBasePath.replace(
                ':assetId',
                formValues.companyPresentation.fileId,
              )}?token=${localStorageFileToken ?? sessionStorageFileToken}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Button icon={<ProfileOutlined />} type='ghost'>
                {formValues.companyPresentation.fileName ?? t('misc.companyPresentation')}
              </Button>
            </Link>
            <Button icon={<DeleteOutlined />} type='ghost' onClick={removePresentation} />
          </>
        )}
      </Form.Item>
      <Form.Item
        label={t('misc.videoPresentation')}
        name='videoLink'
        tooltip={t('tooltip.videoPresentation')}
      >
        <Input addonBefore='https://' />
      </Form.Item>
      <h3>{t('misc.socialAccounts')}</h3>
      <Form.Item
        label={t('misc.facebook')}
        name='facebook'
        rules={[
          {
            type: 'url',
            message: t('messages.invalidUrl') ?? '',
          },
        ]}
      >
        <Input addonBefore='https://' />
      </Form.Item>
      <Form.Item
        label={t('misc.linkedin')}
        name='linkedin'
        rules={[
          {
            type: 'url',
            message: t('messages.invalidUrl') ?? '',
          },
        ]}
      >
        <Input addonBefore='https://' />
      </Form.Item>
      <Form.Item
        label={t('misc.instagram')}
        name='instagram'
        rules={[
          {
            type: 'url',
            message: t('messages.invalidUrl') ?? '',
          },
        ]}
      >
        <Input addonBefore='https://' />
      </Form.Item>
      <Form.Item
        label={t('misc.twitter')}
        name='twitter'
        rules={[
          {
            type: 'url',
            message: t('messages.invalidUrl') ?? '',
          },
        ]}
      >
        <Input addonBefore='https://' />
      </Form.Item>
      <Form.Item
        label={t('misc.skype')}
        name='skype'
        rules={[
          {
            type: 'url',
            message: t('messages.invalidUrl') ?? '',
          },
        ]}
      >
        <Input addonBefore='https://' />
      </Form.Item>
      <Form.Item
        label={t('misc.youtube')}
        name='youtube'
        rules={[
          {
            type: 'url',
            message: t('messages.invalidUrl') ?? '',
          },
        ]}
      >
        <Input addonBefore='https://' />
      </Form.Item>
      <h3>{t('misc.description')}</h3>
      <Form.Item label={t('misc.companyProfile')} name='companyProfile'>
        <Input />
      </Form.Item>
      <Form.Item label={t('misc.missionStatement')} name='missionStatement'>
        <Input />
      </Form.Item>
    </div>
  );
};

export default FormAbout;
