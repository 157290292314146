import React, { useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Checkbox, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { useUserContext } from '../../context/user.context';
import styles from './login.module.css';
import useLogin from '../../hooks/useLogin';
import config from '../../config';
import { NotificationContext } from '../../context/notification.context';
import Logo from '../../components/logo/logo';

const Login: React.FC = () => {
  const { setUpdated } = useContext(NotificationContext);
  const { login, loading, error } = useLogin();
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { user } = useUserContext();

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      setUpdated(false);
      navigate(config.routes.companies);
    }
  }, [navigate, setUpdated, user]);

  return (
    <div>
      <Logo width={250} />
      <Form
        name='login'
        className={styles.loginForm}
        onFinish={login}
        initialValues={{
          remember: true,
        }}
      >
        {error != null && (
          <Form.Item>
            <Alert message={error} type='error' />
          </Form.Item>
        )}
        <Form.Item
          name='username'
          rules={[
            {
              required: true,
              message: t('login.required.username') ?? '',
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className='site-form-item-icon' />}
            placeholder={t('misc.username') ?? ''}
          />
        </Form.Item>
        <Form.Item
          name='password'
          rules={[
            {
              required: true,
              message: t('login.required.password') ?? '',
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className='site-form-item-icon' />}
            type='password'
            placeholder={t('misc.password') ?? ''}
          />
        </Form.Item>
        <div className={styles.spaceBetween}>
          <Form.Item name='remember' valuePropName='checked' noStyle>
            <Checkbox>{t('login.rememberMe')}</Checkbox>
          </Form.Item>
          <Link to={config.routes.forgotPassword} className={styles.forgotPassword}>
            {t('login.forgotPassword')}
          </Link>
        </div>

        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            size='large'
            className={cx('login-form-button', styles.loginBtn)}
            loading={loading}
          >
            {t('login.signIn')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
