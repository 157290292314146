import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import PageTitle from '../../components/pageTitle/pageTitle';
import NotFound from '../notFound/notFound';
import { CompanyInterface } from '../../models/companyInterface';
import { getUser } from '../../services/services';
import CompanyForm from '../../components/companyForm/companyForm';
import { useUserContext } from '../../context/user.context';
import { UserRole } from '../../consts/UserRoleEnum';
import { PaymentProtectionStatusEnum } from '../../consts/PaymentProtectionStatusEnum';
import config from '../../config';

const EditCompany = ({ myCompany }: { myCompany?: boolean }): ReactElement => {
  const { t } = useTranslation('common');

  const { id } = useParams();
  const { user } = useUserContext();

  const companyId = useMemo(() => {
    if (myCompany && user.id !== undefined) return String(user.id);
    return id;
  }, [id, myCompany, user]);

  const [companyData, setCompanyData] = useState<CompanyInterface>();
  const [loading, setLoading] = useState<boolean>(true);

  const prepareData = useCallback(async (company: CompanyInterface): Promise<CompanyInterface> => {
    if (company?.registrationDate !== undefined)
      company.registrationDate = dayjs(company.registrationDate, config.dateFormat.universalFormat);

    if (company?.membershipDue !== undefined)
      company.membershipDue = dayjs(company.membershipDue, config.dateFormat.universalFormat);

    if (company?.paymentProtectionValidityDate)
      company.paymentProtectionValidityDate = dayjs(
        company.paymentProtectionValidityDate,
        config.dateFormat.universalFormat,
      );

    if (company?.establishedIn !== undefined)
      company.establishedIn = dayjs(company.establishedIn, config.dateFormat.year);

    if (company.paymentProtection === PaymentProtectionStatusEnum.ACTIVE)
      company.paymentProtection = true;

    if (company.paymentProtection === PaymentProtectionStatusEnum.DISABLED)
      company.paymentProtection = false;

    return company;
  }, []);

  const setCompany = useCallback(
    async (id: string): Promise<void> => {
      setLoading(true);
      const company = (await getUser(id)) as CompanyInterface;
      if (company === undefined) return;
      const preparedCompany = await prepareData(company);
      setCompanyData(preparedCompany);
      setLoading(false);
    },
    [prepareData],
  );

  useEffect(() => {
    if (companyId !== undefined) setCompany(companyId);
  }, [companyId, setCompany]);

  if (loading)
    return (
      <>
        <LoadingOutlined />
      </>
    );

  if (companyData === undefined) return <NotFound />;

  if (myCompany && user.role !== UserRole.MEMBER) return <NotFound />;

  return (
    <div>
      <PageTitle title={t('pageTitles.settings')} />
      <CompanyForm initValues={companyData} companyId={companyData.id} />
    </div>
  );
};

export default EditCompany;
