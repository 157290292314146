import React, { ReactElement, ReactNode } from 'react';
import styles from './logoBoxDisplay.module.css';

const LogoBoxDisplay = ({ children }: { children: ReactNode }): ReactElement => {
  return (
    <div className={styles.root}>
      <div className={styles.inner}>{children}</div>
    </div>
  );
};

export default LogoBoxDisplay;
