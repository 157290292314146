import React, { ReactElement, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  BellOutlined,
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
  LinkedinOutlined,
  SkypeOutlined,
  YoutubeOutlined,
} from '@ant-design/icons';
import { CompanyInterface } from '../../../models/companyInterface';
import CardTitle from '../../../components/cardTitle/cardTitle';
import CustomTable from '../../../components/customTable/customTable';
import { CustomTableInterface } from '../../../models/customTableInterface';

const KeepInTouchTable = ({ company }: { company: CompanyInterface }): ReactElement => {
  const { t } = useTranslation('common');

  const prepareLink = useCallback((link: string, text?: string, icon?: ReactElement): any => {
    let href: string = link;
    let labelWithIcon: ReactElement | null = null;

    if (!href.includes('http')) {
      href = 'http://' + href;
    }

    if (icon !== undefined && text !== undefined) {
      labelWithIcon = (
        <>
          {icon} {text}
        </>
      );
    }

    return (
      <Link to={href} target='_blank' rel='noopener noreferrer'>
        {labelWithIcon !== null ? labelWithIcon : text}
      </Link>
    );
  }, []);

  const socialProfiles = useMemo((): CustomTableInterface[] => {
    const prepareData: CustomTableInterface[] = [];

    if (company.facebook !== null && company.facebook !== undefined && company.facebook !== '')
      prepareData.push({
        name: t('misc.facebook'),
        content: prepareLink(company.facebook, String(t('misc.facebook')), <FacebookOutlined />),
      });

    if (company.linkedin !== null && company.linkedin !== undefined && company.linkedin !== '')
      prepareData.push({
        name: t('misc.linkedin'),
        content: prepareLink(company.linkedin, 'linkedin', <LinkedinOutlined />),
      });

    if (company.instagram !== null && company.instagram !== undefined && company.instagram !== '')
      prepareData.push({
        name: t('misc.instagram'),
        content: prepareLink(company.instagram, 'instagram', <InstagramOutlined />),
      });

    if (company.twitter !== null && company.twitter !== undefined && company.twitter !== '')
      prepareData.push({
        name: t('misc.twitter'),
        content: prepareLink(company.twitter, 'twitter', <TwitterOutlined />),
      });

    if (company.skype !== null && company.skype !== undefined && company.skype !== '')
      prepareData.push({
        name: t('misc.skype'),
        content: prepareLink(company.skype, 'skype', <SkypeOutlined />),
      });

    if (company.youtube !== null && company.youtube !== undefined && company.youtube !== '')
      prepareData.push({
        name: t('misc.youtube'),
        content: prepareLink(company.youtube, 'youtube', <YoutubeOutlined />),
      });

    return prepareData;
  }, [company, t, prepareLink]);

  const keepInTouch = useMemo((): CustomTableInterface[] => {
    const prepareData: CustomTableInterface[] = [];

    if (company.primaryContact && company.phoneNo)
      prepareData.push({
        name: t('misc.primaryContact'),
        content: company.primaryContact,
      });
    
    if (company.phoneNo)
      prepareData.push({
        name: t('misc.phoneNo'),
        content: company.phoneNo,
      });

    if (company.email)
      prepareData.push({
        name: t('misc.email'),
        content: company.email,
      });

    if (company.website !== null && company.website !== undefined && company.website !== '')
      prepareData.push({
        name: t('misc.website'),
        content: prepareLink(company.website, company.website),
      });

    if (socialProfiles.length > 0)
      prepareData.push({
        name: t('misc.socialAccounts'),
        content: (
          <>
            {socialProfiles.map((element: CustomTableInterface) => {
              return <div key={element.name}>{element.content}</div>;
            })}
          </>
        ),
      });

    return prepareData;
  }, [company, t, prepareLink, socialProfiles]);

  if (keepInTouch.length === 0) return <></>;

  return (
    <>
      <CardTitle type='bold' title={t('misc.keepInTouch')} icon={<BellOutlined />} />
      <CustomTable data={keepInTouch} />
    </>
  );
};

export default KeepInTouchTable;
