import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import { CompaniesTableInterface } from './companiesTableInterface';
import styles from './companiesTable.module.css';
import { Space, Tag } from 'antd';
import { Link } from 'react-router-dom';
import config from '../../../config';
import { getUserStatusBadge } from '../../../utils/utils';

const TableColumns = (): ColumnsType<CompaniesTableInterface> => {
  const { t } = useTranslation('common');

  const columns: ColumnsType<CompaniesTableInterface> = [
    {
      title: t('misc.id'),
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => Number(a.id) - Number(b.id),
      render: (id) => (
        <Link to={config.routes.company.replace(':id', id)} className={styles.colorInherit}>
          {id}
        </Link>
      ),
    },
    {
      title: t('misc.name'),
      dataIndex: 'companyName',
      key: 'companyName',
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
      render: (companyName, row) => (
        <Link to={config.routes.company.replace(':id', row.id)} className={styles.colorInherit}>
          {companyName}
        </Link>
      ),
    },
    {
      title: t('misc.country'),
      dataIndex: 'country',
      key: 'country',
      sorter: (a, b) => a.country.localeCompare(b.country),
    },
    {
      title: t('misc.status'),
      key: 'status',
      dataIndex: 'status',
      sorter: (a, b) => Number(a.status) - Number(b.status),
      render: (_, { status }) => getUserStatusBadge({ t, status: Number(status) }),
    },
    {
      title: t('misc.category'),
      key: 'userCategory',
      dataIndex: 'userCategory',
      sorter: (a, b) => a.userCategory.localeCompare(b.userCategory),
      render: (_, { userCategory }) => <Tag key={userCategory}>{userCategory}</Tag>,
    },
    {
      title: t('misc.action'),
      key: 'action',
      render: (_, record) => (
        <Space size='middle'>
          <Link to={config.routes.company.replace(':id', record.id)}>{t('buttons.view')}</Link>
          <Link to={config.routes.editCompany.replace(':id', record.id)}>{t('buttons.edit')}</Link>
        </Space>
      ),
    },
  ];

  return columns;
};

export default TableColumns;
