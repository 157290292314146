import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { PlusSquareOutlined, ExportOutlined } from '@ant-design/icons';
import PageTitle from '../../components/pageTitle/pageTitle';
import { UserRole } from '../../consts/UserRoleEnum';
import { useUserContext } from '../../context/user.context';
import { CompaniesFilterParamsType, CompaniesListResponse } from './types';
import { CompaniesTableDataType, CompanyInterface } from '../../models/companyInterface';
import { getCompanies } from '../../services/services';
import CompaniesTable from './companiesTable/companiesTable';
import CompanyBoxList from './companyBoxList/companyBoxList';
import CompanyFilters from './companyFilters/companyFilters';
import styles from './companies.module.css';
import EmailAllButton from './emailAllButton';
import config from '../../config';
import Mapbox from '../../components/map/map';
import useDebounce from '../../hooks/useDebounce';
import useDownloadFile from '../../hooks/useDownloadFile';
import { UserStatus } from '../../consts/UserStatus';

const PageCompanies: React.FC = () => {
  const { t } = useTranslation('common');

  const { user } = useUserContext();
  const [companiesData, setCompaniesData] = useState<CompaniesListResponse>();
  const [companiesMapEmail, setCompaniesMapEmail] = useState<CompaniesListResponse>();

  const { downloadFile, loading } = useDownloadFile();

  const initialParams = useMemo(
    (): CompaniesFilterParamsType => ({
      limit: user.role === UserRole.MEMBER ? 12 : undefined,
    }),
    [user.role],
  );

  const [params, setParams] = useState<CompaniesFilterParamsType>(initialParams);
  const [finalParams, setFinalParams] = useState<CompaniesFilterParamsType>(initialParams);
  const [searchTerm, setSearchTerm] = useState<string>();

  const debouncedSearch = useDebounce(searchTerm, 1000);

  useEffect(() => {
    setFinalParams((prevState) => {
      return {
        ...prevState,
        page: 1,
        companyName: debouncedSearch,
      };
    });
  }, [debouncedSearch]);

  useEffect(() => {
    setFinalParams(params);
  }, [params]);

  useEffect(() => {
    getCompanies(finalParams).then((companies) => {
      setCompaniesData(companies);
    });
  }, [finalParams]);

  const handleSetParams = (newParams: CompaniesFilterParamsType): void => {
    const paramsData = { ...params, ...newParams };
    if (!newParams.page) {
      paramsData.page = 1;
    }
    setParams(paramsData);
  };

  useEffect(() => {
    const setMapCompanies = async (): Promise<void> => {
      let params: CompaniesFilterParamsType = {
        limit: 99999,
        status: UserStatus.ENABLED.toString(),
      };
      if (user.role === UserRole.MEMBER)
        params = {
          ...params,
          companyName: finalParams.companyName,
          country: finalParams.country,
          status: finalParams.status,
        };

      const companies = await getCompanies(params);
      setCompaniesMapEmail(companies);
    };
    setMapCompanies();
  }, [finalParams.companyName, finalParams.country, finalParams.status, user]);

  const dataTable = useMemo((): CompaniesTableDataType[] => {
    if (companiesData?.items === undefined || companiesData?.items === null) return [];
    return companiesData.items.map((company: CompanyInterface) => ({
      id: String(company.id),
      companyName: company.companyName,
      country: company.country,
      status: Number(company.status),
      userCategory: company.userCategory,
    }));
  }, [companiesData]);

  const displayCompanies = (): ReactElement => {
    if (!companiesData || companiesData === null || !dataTable) {
      return <></>;
    }
    if (user.role === UserRole.ADMIN || user.role === UserRole.SUPER_ADMIN) {
      return (
        <CompaniesTable
          dataTable={dataTable}
          handleSetParams={handleSetParams}
          companiesData={companiesData}
        />
      );
    }

    return <CompanyBoxList companiesData={companiesData} setParams={setParams} />;
  };

  const exportUsers = (): void => {
    downloadFile(
      config.apiRoutes.exportUsers,
      `${t('misc.export')} ${t('pageTitles.companies')}.csv`,
    );
  };

  const exportMembers = (): void => {
    downloadFile(
      config.apiRoutes.exportMembers,
      `${t('misc.export')} ${t('pageTitles.companies')}.xlsx`,
      {
        responseType: 'arraybuffer',
      },
    );
  };

  const TitleButtons = (): ReactElement => {
    if (user.role === UserRole.ADMIN || user.role === UserRole.SUPER_ADMIN)
      return (
        <>
          {companiesMapEmail?.items !== undefined && (
            <EmailAllButton companies={companiesMapEmail?.items} />
          )}
          <Button icon={<ExportOutlined />} onClick={exportUsers} loading={loading}>
            {t('buttons.exportAll')}
          </Button>
          <Link to={config.routes.addCompany}>
            <Button icon={<PlusSquareOutlined />} type='primary'>
              {t('buttons.addNew')}
            </Button>
          </Link>
        </>
      );

    if (user.role === UserRole.MEMBER) {
      return (
        <Button icon={<ExportOutlined />} onClick={exportMembers} loading={loading}>
          {t('buttons.exportAll')}
        </Button>
      );
    }

    return <></>;
  };

  return (
    <>
      {user.role === UserRole.MEMBER && companiesMapEmail?.items && (
        <Mapbox companies={companiesMapEmail?.items} />
      )}
      <PageTitle title={t('pageTitles.companies')} buttons={TitleButtons()} />
      <div className={styles.root}>
        <CompanyFilters setParams={setParams} setSearchTerm={setSearchTerm} />
        {displayCompanies()}
      </div>
    </>
  );
};

export default PageCompanies;
