import React, { ReactElement, useCallback, useMemo } from 'react';
import { Col, Row, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { CalendarOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { CompanyInterface } from '../../../models/companyInterface';
import styles from './topSection.module.css';
import config from '../../../config';
import LogoBoxDisplay from '../../../components/logoBoxDisplay/logoBoxDisplay';
import CompanyActions from '../companyActions/companyActions';
import { LocalStorageEnum } from '../../../consts/LocalStorageEnum';
import { PaymentProtectionStatusEnum } from '../../../consts/PaymentProtectionStatusEnum';
import { ReactComponent as ShieldFill } from '../../../assets/shieldFill.svg';
import { MembersEnum, PartnersEnum } from '../../../consts/UserSubcategories';
import { UserCategory } from '../../../consts/UserCategoryEnum';

const CompanyTopSection = ({ company }: { company: CompanyInterface }): ReactElement => {
  const { t } = useTranslation('common');
  const localStorageFileToken = localStorage.getItem(LocalStorageEnum.FILE_ACCESS_TOKEN);
  const sessionStorageFileToken = sessionStorage.getItem(LocalStorageEnum.FILE_ACCESS_TOKEN);
  const memberSince = useCallback((): string => {
    const date = new Date(String(company.registrationDate));
    return date.toDateString();
  }, [company.registrationDate]);

  const infoUnderTitle = useMemo((): Array<{ text: ReactElement; icon?: ReactElement }> => {
    const infoUnderTitle: Array<{text: ReactElement, icon?: ReactElement}> = [
      {
        text: <>{company.userCategory === UserCategory.MEMBER ? t('misc.member') : company.userCategory === UserCategory.PARTNER && t('misc.partner') } {t('misc.since')} {memberSince()}</>,
        icon: <CalendarOutlined />,
      },
      {
        text: <>{company.country}</>,
        icon: <EnvironmentOutlined />,
      },
    ];

    if (
      config.features.paymentProtection &&
      company.paymentProtection === PaymentProtectionStatusEnum.ACTIVE &&
      company.paymentProtectionValidityDate &&
      dayjs().isBefore(dayjs(company.paymentProtectionValidityDate))
    )
      infoUnderTitle.push({
        text: <>{t('misc.paymentProtectionUntil').replace(
          ':date',
          String(company.paymentProtectionValidityDate).substring(0, 10),
        )}</>,
        icon: <ShieldFill className={styles.shieldFill} />,
      });

    if (config.features.nsrnCategories) {
      const memberTags = (): ReactElement => (
        <>
          {company.memberCategories?.sort((a, b) => {
            if (a === MembersEnum.FORWARDER) return -1;
            if (b === MembersEnum.FORWARDER) return 1;
            return a.localeCompare(b);
          }).map((category) => {
            let categName: string = '';
            if (category === MembersEnum.TRUCKING) categName = t('companyCategories.trucking');
            if (category === MembersEnum.WAREHOUSING) categName = t('companyCategories.warehousing');
            if (category === MembersEnum.CUSTOMS_CLEARANCE) categName = t('companyCategories.customsClearance');
            if (category === MembersEnum.FORWARDER) categName = t('companyCategories.forwarder');
            return <Tag key={category} color={process.env.REACT_APP_PRIMARY_COLOR}>{categName}</Tag>
          })}
        </>
      )

      const partnerTags = (): ReactElement => (
        <>
          {company.partnerCategories?.map((category) => {
            let categName: string = '';
            if (category === PartnersEnum.TERMINAL_PORTS) categName = t('companyCategories.terminalPorts');
            if (category === PartnersEnum.OPERATORS) categName = t('companyCategories.operators');
            if (category === PartnersEnum.CONSULTANCY) categName = t('companyCategories.consultancy');
            if (category === PartnersEnum.OTHER) categName = company.partnerOtherCategory ? company.partnerOtherCategory : t('companyCategories.other');
            return <Tag key={category} color={process.env.REACT_APP_PRIMARY_COLOR}>{categName}</Tag>
          })}
        </>
      )
      
      
      infoUnderTitle.splice(0, 0, {
        text: <>
          {memberTags()}
          {
            company.userCategory === UserCategory.PARTNER && company.partnerOtherCategory ?
              <Tag key={company.partnerOtherCategory} color={process.env.REACT_APP_PRIMARY_COLOR}>{company.partnerOtherCategory}</Tag>
              : partnerTags()
          }</>
      });

    }

    return infoUnderTitle;
  }, [company, memberSince, t]);

  return (
    <div className={styles.root}>
      <Row gutter={50}>
        <Col lg={17} xs={24}>
          {company.companyLogo?.fileId != null && (
            <div className={styles.logo}>
              <LogoBoxDisplay>
                <img
                  src={`${config.fileViewBasePath.replace(
                    ':assetId',
                    company.companyLogo?.fileId,
                  )}?token=${localStorageFileToken ?? sessionStorageFileToken}`}
                  alt={company.companyName}
                />
              </LogoBoxDisplay>
            </div>
          )}
          <div className={styles.about}>
            <h1 className={styles.name}>{company.companyName}</h1>
            {infoUnderTitle.length > 0 &&
              infoUnderTitle.map((element) => (
                <div key={String(element.text)} className={styles.infoLine}>
                  {element.icon} <span>{element.text}</span>
                </div>
              ))}
          </div>
        </Col>
        <Col lg={7} xs={24} className={styles.right}>
          <CompanyActions company={company} />
        </Col>
      </Row>
    </div>
  );
};

export default CompanyTopSection;
