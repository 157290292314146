import React, { ReactElement, ReactNode } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import styles from './companyForm.module.css';
import cx from 'classnames';

interface TabWrapperType {
  children: ReactNode;
  activeKey: string;
  changeKey: (key: string) => void;
  last?: boolean;
  boxed?: boolean;
}

const TabWrapper = ({
  children,
  activeKey,
  changeKey,
  last,
  boxed,
}: TabWrapperType): ReactElement => {
  const { t } = useTranslation('common');

  const prevTab = (): void => {
    const newKey = (Number(activeKey) - 1).toString();
    changeKey(newKey);
  };

  const nextTab = (): void => {
    const newKey = (Number(activeKey) + 1).toString();
    changeKey(newKey);
  };

  return (
    <div className={cx(styles.tabWrapper, (boxed ?? false) && styles.boxed)}>
      <div>{children}</div>
      <div className={styles.tabsNavigation}>
        <Button icon={<LeftOutlined />} onClick={prevTab} disabled={activeKey === '1'}>
          {t('buttons.back')}
        </Button>
        {!(last ?? false) && (
          <Button type='primary' onClick={nextTab}>
            {t('buttons.next')} <RightOutlined />
          </Button>
        )}
      </div>
    </div>
  );
};

export default TabWrapper;
