import React, { ReactElement, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import 'mapbox-gl/dist/mapbox-gl.css';
import cx from 'classnames';
import { EnvironmentOutlined } from '@ant-design/icons';
import MapGL, {
  Marker,
  Popup,
  GeolocateControl,
  FullscreenControl,
  NavigationControl,
  ScaleControl,
} from '@urbica/react-map-gl';
import Cluster from '@urbica/react-map-gl-cluster';
import styles from './map.module.css';
import config from '../../config';
import { CompanyInterface } from '../../models/companyInterface';
import Pin from './pin';
import { LocalStorageEnum } from '../../consts/LocalStorageEnum';
import { ClusterMarkerPropsInterface, MapClusterInterface, MapViewportInterface } from './types';
import useResponsiveLayout from '../../hooks/useResponsiveLayout';
import LogoBoxDisplay from '../logoBoxDisplay/logoBoxDisplay';

const Mapbox = ({ companies }: { companies: CompanyInterface[] }): ReactElement => {
  const localStorageFileToken = localStorage.getItem(LocalStorageEnum.FILE_ACCESS_TOKEN);
  const sessionStorageFileToken = sessionStorage.getItem(LocalStorageEnum.FILE_ACCESS_TOKEN);
  const [popupInfo, setPopupInfo] = useState<CompanyInterface | null>(null);
  const clusterRef = useRef<any>(null);
  const [viewport, setViewport] = useState<MapViewportInterface>({
    latitude: companies.length === 1 && companies[0].lat ? companies[0].lat : 46,
    longitude: companies.length === 1 && companies[0].lng ? companies[0].lng : 15,
    zoom: 3.5,
  });

  const markerClick = (company: CompanyInterface) => (e: PointerEvent) => {
    e.stopPropagation();
    setPopupInfo(company);
  };
  const { isMobileLayout } = useResponsiveLayout();
  const pins = useMemo(
    () =>
      companies?.map(
        (company: CompanyInterface, index: number) =>
          company.lng && (
            <Marker
              key={`marker-${index}`}
              longitude={Number(company.lng)}
              latitude={Number(company.lat)}
              anchor='bottom'
              onClick={markerClick(company)}
            >
              <Pin category={company.userCategory} />
            </Marker>
          ),
      ),
    [companies],
  );

  const ClusterMarker = (props: ClusterMarkerPropsInterface): ReactElement => {
    const { longitude, latitude, pointCount } = props;

    const onClick = (): void => {
      const { onClick, ...cluster } = props;
      onClick(cluster);
    };

    return (
      <Marker longitude={longitude} latitude={latitude}>
        <div onClick={onClick} className={styles.clusterCount}>
          {pointCount}
        </div>
      </Marker>
    );
  };

  const onClusterClick = (cluster: MapClusterInterface): void => {
    const { clusterId, longitude, latitude } = cluster;

    const supercluster = clusterRef.current.getCluster();
    const zoom = supercluster.getClusterExpansionZoom(clusterId);

    const newVewport = {
      ...viewport,
      latitude,
      longitude,
      zoom,
    };

    setViewport(newVewport);
  };

  const changeViewport = (viewport: MapViewportInterface): void => {
    setViewport(viewport);
  };

  const closePinPopup = (): void => {
    setPopupInfo(null);
  };

  return (
    <div className={cx(styles.root, isMobileLayout && styles.mobile)}>
      <div className={styles.map}>
        <MapGL
          {...viewport}
          mapStyle={config.mapBoxStyle}
          accessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          onViewportChange={changeViewport}
          style={{ width: '100%', height: '100%' }}
        >
          <GeolocateControl position='top-right' />
          <FullscreenControl position='top-right' />
          <NavigationControl position='top-right' />
          <ScaleControl position='bottom-right' />
          <Cluster
            radius={40}
            extent={512}
            nodeSize={64}
            ref={clusterRef}
            component={(cluster: MapClusterInterface) => (
              <ClusterMarker onClick={onClusterClick} {...cluster} />
            )}
          >
            {pins}
          </Cluster>

          {popupInfo !== null && (
            <Popup
              anchor='top'
              longitude={Number(popupInfo.lng)}
              latitude={Number(popupInfo.lat)}
              closeButton={false}
              closeOnClick={false}
              className={styles.pinPopUp}
            >
              <div onClick={closePinPopup}>
                {popupInfo.companyLogo && (
                  <LogoBoxDisplay>
                    <img
                      className={styles.popupImg}
                      src={`${config.fileViewBasePath.replace(
                        ':assetId',
                      popupInfo.companyLogo.fileId as string,
                      )}?token=${localStorageFileToken ?? sessionStorageFileToken}`}
                    />
                  </LogoBoxDisplay>
                )}
                <Link to={config.routes.company.replace(':id', String(popupInfo.id))}>
                  <h3 className={styles.pinCompanyName}>{popupInfo.companyName}</h3>
                </Link>
                <div>
                  <EnvironmentOutlined /> {popupInfo.city}, {popupInfo.county}
                </div>
              </div>
            </Popup>
          )}
        </MapGL>
      </div>
    </div>
  );
};

export default Mapbox;
