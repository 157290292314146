import { Form, FormInstance, message } from 'antd';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { StoreValue } from 'antd/es/form/interface';
import { axiosInstance } from '../api/AxiosInstance';
import { FieldError } from '../components/companyForm/companyForm';
import config from '../config';
import { PaymentProtectionStatusEnum } from '../consts/PaymentProtectionStatusEnum';
import { UserCategory } from '../consts/UserCategoryEnum';
import { UserStatus } from '../consts/UserStatus';
import { CompanyFormInterface } from '../models/companyFormInterface';
import { CompanyInterface } from '../models/companyInterface';
import { LocalStorageEnum } from '../consts/LocalStorageEnum';

interface CompanyFormHook {
  activeKey: string;
  changeKey: (key: string) => void;
  form: FormInstance<any>;
  formValues: CompanyFormInterface;
  logoPreviewUrl: string | null;
  setLogoPreviewUrl: React.Dispatch<React.SetStateAction<string | null>>;
  setFormValues: React.Dispatch<React.SetStateAction<CompanyFormInterface>>;
  formErrors: FieldError[] | null;
  submitErrors: React.Dispatch<React.SetStateAction<FieldError[] | null>>;
  handleSubmit: (values: FormData) => void;
  handleFormChange: () => void;
  handleFormErrors: () => Promise<void>;
  phoneNumberValidator: (_: any, value: StoreValue) => Promise<string | undefined>;
  loading: boolean;
}

const useCompanyForm = ({
  initValues,
  companyId,
}: {
  initValues?: CompanyInterface;
  companyId?: string;
}): CompanyFormHook => {
  const { t } = useTranslation('common');
  const localStorageFileToken = localStorage.getItem(LocalStorageEnum.FILE_ACCESS_TOKEN);
  const sessionStorageFileToken = sessionStorage.getItem(LocalStorageEnum.FILE_ACCESS_TOKEN);
  const initialValues: CompanyFormInterface = {
    status: UserStatus.ENABLED,
    registrationDate: undefined,
    username: '',
    email: '',
    password: '',
    phoneNo: '',
    vatNo: '',
    companyName: '',
    primaryContact: '',
    address: '',
    postalCode: '',
    contacts: [],
    documents: [],
    userCategory: UserCategory.MEMBER,
    address2: '',
  };

  const [formValues, setFormValues] = useState<CompanyFormInterface>(
    (initValues as CompanyFormInterface) ?? initialValues,
  );
  const [formErrors, setFormErrors] = useState<FieldError[] | null>(null);
  const [submitErrors, setSubmitErrors] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [form] = Form.useForm();

  const navigate = useNavigate();

  const handleFormErrors = useCallback(async (): Promise<void> => {
    let errors = form.getFieldsError();
    errors = errors.filter((error) => error.errors.length > 0);
    setFormErrors(errors);
    setSubmitErrors(null);
  }, [form]);

  const handleFormChange = useCallback((): void => {
    setFormValues(form.getFieldsValue());
  }, [form]);

  const [logoPreviewUrl, setLogoPreviewUrl] = useState<string | null>(null);

  useEffect(() => {
    if (formValues.companyLogo?.file !== undefined)
      setLogoPreviewUrl(URL.createObjectURL(formValues.companyLogo.file));
    if (companyId !== undefined && formValues.companyLogo?.fileId !== undefined)
      setLogoPreviewUrl(
        `${config.fileViewBasePath.replace(':assetId', formValues.companyLogo?.fileId)}?token=${
          localStorageFileToken ?? sessionStorageFileToken
        }`,
      );
  }, [companyId, formValues.companyLogo, localStorageFileToken, sessionStorageFileToken]);

  useEffect(() => {
    handleFormErrors();
  }, [formValues, handleFormErrors]);

  const [activeKey, setActiveKey] = useState<string>('1');
  const changeKey = (key: string): void => {
    setActiveKey(key);
  };

  const prepareFormValues = async (values: FormData): Promise<CompanyFormInterface> => {
    const preparedValues: any = values;

    if (preparedValues.documents !== undefined && Array.isArray(preparedValues.documents)) {
      for (let index = 0; index < preparedValues.documents.length; index++) {
        if (preparedValues.documents[index].file !== undefined)
          preparedValues.documents[index].file = preparedValues.documents[index]?.file?.file;
        if (preparedValues.documents[index].createdAt !== undefined)
          delete preparedValues.documents[index].createdAt;
        if (preparedValues.documents[index].updatedAt !== undefined)
          delete preparedValues.documents[index].updatedAt;
      }
    }
    if (preparedValues.systemEmails !== undefined) {
      preparedValues.systemEmails = String(preparedValues.systemEmails);
    }
    if (preparedValues?.paymentProtection !== undefined && preparedValues.paymentProtection)
      preparedValues.paymentProtection = PaymentProtectionStatusEnum.ACTIVE;
    if (preparedValues?.paymentProtection !== undefined && !preparedValues.paymentProtection)
      preparedValues.paymentProtection = PaymentProtectionStatusEnum.DISABLED;

    if (preparedValues.registrationDate !== undefined) {
      preparedValues.registrationDate = dayjs(
        preparedValues.registrationDate.$d.toDateString(),
      ).format(config.dateFormat.universalFormat);
    }
    if (preparedValues.membershipDue !== undefined) {
      preparedValues.membershipDue = dayjs(preparedValues.membershipDue.$d.toDateString()).format(
        config.dateFormat.universalFormat,
      );
    }
    if (preparedValues.paymentProtectionValidityDate !== undefined) {
      preparedValues.paymentProtectionValidityDate = dayjs(
        preparedValues.paymentProtectionValidityDate.$d.toDateString(),
      ).format(config.dateFormat.universalFormat);
    }
    if (preparedValues.establishedIn !== undefined) {
      preparedValues.establishedIn = String(preparedValues.establishedIn.$d.getFullYear());
    }
    if (preparedValues.contacts !== undefined && Array.isArray(preparedValues.contacts)) {
      for (let index = 0; index < preparedValues.contacts.length; index++) {
        if (preparedValues.contacts[index].userId !== undefined)
          delete preparedValues.contacts[index].userId;
        if (preparedValues.contacts[index].createdAt !== undefined)
          delete preparedValues.contacts[index].createdAt;
        if (preparedValues.contacts[index].updatedAt !== undefined)
          delete preparedValues.contacts[index].updatedAt;
      }
    }

    if (preparedValues.paymentProtection !== undefined)
      if (preparedValues.paymentProtection === 'true') {
        preparedValues.paymentProtection = PaymentProtectionStatusEnum.ACTIVE;
      } else {
        preparedValues.paymentProtection = PaymentProtectionStatusEnum.DISABLED;
      }

    if (preparedValues.confirmPassword) delete preparedValues.confirmPassword;

    return preparedValues;
  };

  const handleSubmit = useCallback(
    async (values: FormData) => {
      try {
        setLoading(true);
        handleFormErrors();
        const preparedValues = await prepareFormValues(values);
        let response: Response;
        if (companyId === undefined) {
          response = await axiosInstance.post(config.apiRoutes.members, preparedValues, {
            headers: {
              ...{ 'Content-Type': 'multipart/form-data' },
            },
          });
        } else {
          response = await axiosInstance.put(
            config.apiRoutes.updateMembers.replace(':id', companyId),
            preparedValues,
            {
              headers: {
                ...{ 'Content-Type': 'multipart/form-data' },
              },
            },
          );
        }
        if (response.status === 201 || response.status === 200) {
          navigate(config.routes.companies);
          message.success(t('messages.savedCompany'));
        }
        setLoading(false);
      } catch (error) {
        if (error instanceof AxiosError) {
          if (
            error.response?.status === 409 &&
            error.response?.data?.message === 'USER_ALREADY_EXISTS'
          ) {
            setSubmitErrors(t('messages.usernameAlreadyExists'));
          } else {
            setSubmitErrors(error.response?.data.message);
          }
        } else {
          message.error(t('messages.errorTryLater'));
        }
        setLoading(false);
      }
    },
    [handleFormErrors, t, companyId, navigate],
  );

  const phoneNumberValidator = async (_: any, value: StoreValue): Promise<any> => {
    if (!value) return;
    if (!isValidPhoneNumber(value)) {
      return await Promise.reject(t('messages.invalidPhoneNumber'));
    }
  };

  return {
    activeKey,
    changeKey,
    form,
    formValues,
    logoPreviewUrl,
    setLogoPreviewUrl,
    setFormValues,
    formErrors,
    submitErrors,
    handleSubmit,
    handleFormChange,
    handleFormErrors,
    phoneNumberValidator,
    loading,
  };
};

export default useCompanyForm;
